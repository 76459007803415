import React, { useState } from "react";
import '../styles/Portfolio.css';  // Ensure this file contains the updated CSS

const Portfolio = () => {
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    return (
        <section id="portfolio" className="portfolio-section">
            <div className="container">
                <h2 className="title">Portfólio</h2>

                {/* First site */}
                <div className="portfolio-card">
                    <iframe
                        src="https://ecprojetos.com.br/"
                        className="portfolio-iframe"
                        title="Site Eagle Consulting"
                        loading="lazy"
                    >
                    </iframe>
                </div>

                {/* Optionally, you can add a link below the iframe */}
                <a
                    href="https://ecprojetos.com.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="portfolio-link"
                >
                    Visitar Site
                </a>

                {/* More portfolio sites, initially hidden */}
                {showMore && (
                    <>
                        <div className="portfolio-card">
                            <iframe
                                src="https://sbkmotorrad.netlify.app/"
                                className="portfolio-iframe"
                                title="Another Website"
                                loading="lazy"
                            >
                            </iframe>
                        </div>
                    </>
                )}

                {/* Show more/less button */}
                <button onClick={toggleShowMore} className="show-more-btn">
                    {showMore ? "Exibir Menos" : "Exibir Mais"}
                </button>
            </div>
        </section>
    );
};

export default Portfolio;
