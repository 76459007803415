import React from 'react';
import '../styles/Footer.css'; // Import the CSS file

const Footer = () => {
    return (
        <footer>
            <div className="footer-content">
                <p>&copy; 2024 L&L IT Consulting. Todos os Direitos Reservados.</p>
                <p>Telefone: (48) 99103-9199 | Email: itconsultingll@gmail.com</p>
            </div>
        </footer>
    );
};

export default Footer;
