import React from 'react';
import '../styles/Services.css'; // Make sure to link your CSS

const Services = () => {
    return (
        <section id='services' className="services">
            <h2>Nossos Serviços</h2>
            <div className="service-container">
                <div className="service-box">
                    <div className="icon react-icon"></div>
                    <h3>Desenvolvimento de Websites Responsivos</h3>
                    <p>
                        Criar sites responsivos, adaptáveis a desktops, tablets e smartphones, melhora a experiência do usuário, otimiza o SEO e aumenta o engajamento.
                    </p>
                </div>

                <div className="service-box">
                    <div className="icon ecommerce-icon"></div>
                    <h3>Plataformas de E-commerce</h3>
                    <p>
                        Desenvolvemos lojas virtuais completas, integrando soluções de pagamento, gestão de estoque e ferramentas de marketing para aumentar suas vendas online.
                    </p>
                </div>

                <div className="service-box">
                    <div className="icon wordpress-icon"></div>
                    <h3>Manutenção e Suporte Técnico</h3>
                    <p>
                        Cuidamos da atualização, segurança e performance do seu site com planos de manutenção contínua e suporte técnico especializado.
                    </p>
                </div>
            </div>

            {/* Continuation of Services */}
            <div className="service-container">
                <div className="service-box">
                    <div className="icon seo-icon"></div>
                    <h3>SEO e Otimização de Performance</h3>
                    <p>
                        Aprimoramos seu site para garantir que ele seja rápido, eficiente e bem ranqueado nos motores de busca, aumentando a visibilidade e o tráfego orgânico.
                    </p>
                </div>

                <div className="service-box">
                    <div className="icon app-icon"></div>
                    <h3>Criação de Aplicativos Web</h3>
                    <p>
                        Desenvolvemos aplicativos web personalizados que melhoram processos e oferecem soluções únicas para o seu negócio.
                    </p>
                </div>

                <div className="service-box">
                    <div className="icon server-icon"></div>
                    <h3>Gestão de Servidores e Suporte Técnico</h3>
                    <p>
                        Oferecemos suporte técnico especializado para configurar, gerenciar e otimizar servidores e e-mails, garantindo que seu site funcione com segurança e alta performance.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Services;
