import React from 'react';
import img from '../imgs/home_IMG.png'; // Verifique se o caminho está correto
import '../styles/Home.css';

const Home = () => {
    return (
        <section id='about' className="container-principal">
            <div className='texto-principal'>
                <p>Somos especialistas em soluções digitais que transformam negócios. 
                    Oferecemos serviços completos, desde a criação de sites responsivos, 
                    e-commerces e aplicativos web. Nosso foco é unir tecnologia e estratégia 
                    para garantir resultados eficientes e criativos para sua empresa.</p>
            </div>
            <div className='imagem-principal'>
                <img src={img} alt="Ilustração de soluções digitais" />
            </div>
        </section>
    );
};

export default Home;
