import React, { useState } from 'react';
import '../styles/Header.css'; // Importando o CSS para o Header
import logo from '../imgs/LeL_logo.jpg'; // Importando o logo

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header>
            <nav>
                <img className='logo' src={logo} alt="L&L Logo" />
                <div className='mobile-menu' onClick={toggleMenu}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <ul id='nav-list'className={`nav-list ${menuOpen ? 'open' : ''}`}>
                    <li><a href="#home" onClick={toggleMenu}>Home</a></li>
                    <li><a href="#about" onClick={toggleMenu}>Sobre Nós</a></li>
                    <li><a href="#portfolio" onClick={toggleMenu}>Portfólio</a></li>
                    <li><a href="#services" onClick={toggleMenu}>Serviços</a></li>
                    <li><a href="#team" onClick={toggleMenu}>Nosso Time</a></li>
                    <li><a href="#contato" onClick={toggleMenu}>Contato</a></li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
