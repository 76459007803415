import React from 'react';
import '../styles/AboutUs.css'
import img1 from '../imgs/why_icons/1.png'
import img2 from '../imgs/why_icons/2.png'
import img3 from '../imgs/why_icons/3.png'
import img4 from '../imgs/why_icons/4.png'
import img5 from '../imgs/why_icons/5.png'
import img6 from '../imgs/why_icons/6.png'

const AboutUs = () => {
    return (
        <div>
            {/* First Section */}
            <section className="about-us">
                <h2>Por Que Ter Um Site?</h2>
                <div className="why-content">
                    <div className="why-item">
                        <img src={img1} alt="Disponibilidade 24/7" />
                        <div className="why-text">
                            <h3>Disponibilidade 24/7</h3>
                            <p>Com um site, seu negócio está sempre acessível. Clientes podem encontrar
                                informações sobre seus produtos e serviços a qualquer hora,
                                de qualquer lugar, sem limitações de horário.
                            </p>
                        </div>
                    </div>
                    <div className="why-item right-align">
                        <img src={img2} alt="Credibilidade e Profissionalismo" />
                        <div className="why-text">
                            <h3>Credibilidade e Profissionalismo</h3>
                            <p>Um site bem estruturado transmite confiança. Ele oferece uma imagem profissional e
                                ajuda a estabelecer sua empresa como uma referência no setor, essencial para conquistar
                                novos clientes.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Second Section with white background */}
            <section className="about-us-b white-section">
                <div className="why-content">
                    <div className="why-item">
                        <img src={img3} alt="Base para Marketing Digital" />
                        <div className="why-text">
                            <h3>Base para Marketing Digital</h3>
                            <p>Seu site é o ponto central de qualquer estratégia de marketing digital.
                                Através de SEO, campanhas pagas, e conteúdo relevante, você atrai mais visitantes
                                qualificados e aumenta suas chances de conversão.
                            </p>
                        </div>
                    </div>
                    <div className="why-item right-align">
                        <img src={img4} alt="Melhoria na Comunicação com Clientes" />
                        <div className="why-text">
                            <h3>Melhoria na Comunicação com Clientes</h3>
                            <p>Um site oferece um canal direto de comunicação. Você pode compartilhar
                                atualizações, responder dúvidas frequentes e oferecer suporte em tempo real,
                                melhorando a experiência do cliente e fortalecendo sua marca.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* 3 Section */}
            <section className="about-us">
                <div className="why-content">
                    <div className="why-item">
                        <img src={img5} alt="Geração de Leads e Vendas" />
                        <div className="why-text">
                            <h3>Geração de Leads e Vendas</h3>
                            <p>Um site facilita a captura de leads e pode ser configurado para realizar vendas diretas. Com formulários, e-commerce e ferramentas de agendamento, transformar visitantes em clientes nunca foi tão fácil.

                            </p>
                        </div>
                    </div>
                    <div className="why-item right-align">
                        <img src={img6} alt="Credibilidade e Profissionalismo" />
                        <div className="why-text">
                            <h3>Quebra de Barreiras Geográficas</h3>
                            <p>Com um site, você pode alcançar clientes em qualquer lugar do mundo, expandindo significativamente suas oportunidades de negócio.

                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>


    );
};

export default AboutUs;
