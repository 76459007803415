import React, { useState } from 'react';
import '../styles/Team.css';
import wpp from '../imgs/whatsapp.png';
import lk from '../imgs/linkedin.png';
import nunes from '../imgs/team/nunes.jpeg';
import pasti from '../imgs/team/pasti.jpg';
import rapha from '../imgs/team/rapha.jpg';
import leo from '../imgs/team/leo.jpg';

const teamData = [
    {
        name: 'Lucas Nunes',
        image: nunes, // Image reference
        description: 'Desenvolvedor e tester focado em aplicações no ecossistema de Web, trabalhei em aplicações de pequeno, médio e grande porte, impactando milhões de usuários. Trabalhando tanto no lado do cliente quanto no servidor, prezo por interfaces funcionais e acessíveis, e por serviços seguros e velozes.',
        icons: [
            <a href="https://wa.me/554796012221?text=Ol%C3%A1%2C%20vim%20pelo%20site%20da%20L%26L%20IT%20Consulting%2C%20quero%20conhecer%20mais%20sobre%20a%20empresa%20e%2Fou%20solicitar%20um%20servi%C3%A7o%20" target="_blank" rel="noopener noreferrer">
                <img src={wpp} alt="WhatsApp" />
            </a>,
            <a href="https://www.linkedin.com/in/lucas-nb/" target="_blank" rel="noopener noreferrer">
                <img src={lk} alt="LinkedIn" />
            </a>
        ]
    },
    {
        name: 'Lucas Pasti',
        image: pasti, // Image reference
        description: 'Sou capacitado em Python, com foco em machine learning e análise de dados, utilizando bibliotecas como Pandas e Matplotlib. O Pandas me permite realizar manipulações complexas de dados, enquanto o Matplotlib facilita a visualização eficaz dos resultados.Tenho experiência significativa com Scikit-learn, que utilizo para desenvolver e implementar modelos de machine learning. Com ela, trabalho em tarefas de classificação, regressão e clustering, além de otimizar modelos e realizar validação cruzada.Além disso, sou proficiente em PyTorch, onde construo e treino redes neurais para aplicações em deep learning. Essa experiência abrange a implementação de algoritmos avançados e a personalização de modelos para resolver problemas específicos.Combinando essas ferramentas, consigo abordar projetos de maneira abrangente, desde a análise de dados até a implementação de soluções de inteligência artificial. Estou sempre em busca de novas técnicas e melhores práticas para aprimorar minhas habilidades.',
        icons: [
            <a href="https://wa.me/554792402427?text=Ol%C3%A1%2C%20vim%20pelo%20site%20da%20L%26L%20IT%20Consulting%2C%20quero%20conhecer%20mais%20sobre%20a%20empresa%20e%2Fou%20solicitar%20um%20servi%C3%A7o%20" target="_blank" rel="noopener noreferrer">
                <img src={wpp} alt="WhatsApp" />
            </a>,
            <a href="https://www.linkedin.com/in/lucas-pasti-ferreira-84b845243/" target="_blank" rel="noopener noreferrer">
                <img src={lk} alt="LinkedIn" />
            </a>
        ]
    },
    {
        name: 'Raphael Nunes',
        image: rapha, // Image reference
        description: 'Desenvolvedor capacitado em JavaScript e Python. Com foco em comunicação, trabalho junto aos clientes para entender suas necessidades específicas e garantir que cada solução desenvolvida não apenas atenda, mas supere as expectativas.',
        icons: [
            <a href="https://wa.me/554884138145?text=Ol%C3%A1%2C%20vim%20pelo%20site%20da%20L%26L%20IT%20Consulting%2C%20quero%20conhecer%20mais%20sobre%20a%20empresa%20e%2Fou%20solicitar%20um%20servi%C3%A7o%20" target="_blank" rel="noopener noreferrer">
                <img src={wpp} alt="WhatsApp" />
            </a>
        ]
    },
    {
        name: 'João Leonardo',
        image: leo, // Image reference
        description: 'Atuo na manipulação de bancos de dados SQL e NoSQL, desenvolvendo e otimizando a gestão de dados. No desenvolvimento fullstack de sites, utilizo tecnologias como WordPress, React, Flask e PHP para criar soluções robustas e dinâmicas. Também realizo análises de dados com ferramentas como Python, pandas, Dash, matplotlib e Streamlit, gerando visualizações e relatórios detalhados. No campo do webscraping, trabalho com Selenium, Scrapy e BeautifulSoup para capturar dados da web de forma eficiente. Além disso, desenvolvo e analiso sistemas, aplicando meu conhecimento para construir soluções personalizadas para diferentes demandas.',
        icons: [
            <a href="https://wa.me/5548996237831?text=Ol%C3%A1%2C%20vim%20pelo%20site%20da%20L%26L%20IT%20Consulting%2C%20quero%20conhecer%20mais%20sobre%20a%20empresa%20e%2Fou%20solicitar%20um%20servi%C3%A7o%20" target="_blank" rel="noopener noreferrer">
                <img src={wpp} alt="WhatsApp" />
            </a>,
            <a href="https://www.linkedin.com/in/joao-leonardo-filgueiras-rodrigues-45b8152b2/" target="_blank" rel="noopener noreferrer">
                <img src={lk} alt="LinkedIn" />
            </a>
        ]
    },
];

const Team = () => {
    const [expanded, setExpanded] = useState(Array(teamData.length).fill(false));

    const toggleReadMore = (index) => {
        const newExpanded = [...expanded];
        newExpanded[index] = !newExpanded[index];
        setExpanded(newExpanded);
    };

    return (
        <div id='team' className="team-section">
            <h2>Nosso Time</h2>
            <div className="team-members">
                {teamData.map((member, index) => (
                    <div className={`team-member ${index % 2 === 0 ? '' : 'reverse'}`} key={index}>
                        <img src={member.image} alt={member.name} className="team-photo" />
                        <div className="team-info">
                            <h3>{member.name}</h3>
                            <p>
                                {expanded[index]
                                    ? member.description // Show full description if expanded
                                    : `${member.description.substring(0, 100)}...`} {/* Show preview */}
                            </p>
                            <div className="team-icons">
                                {member.icons.map((icon, idx) => (
                                    <span key={idx}>{icon}</span>
                                ))}
                            </div>
                            <button className="team-button" onClick={() => toggleReadMore(index)}>
                                {expanded[index] ? 'Ler Menos' : 'Leia Mais'}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Team;
