import React from 'react';
import globo from '../imgs/globo.svg'
import '../styles/Hero.css';

const Hero = () => {

    return (
        <section id='home' className="hero">
            <div className='text-container'>
            <h1>L&L IT Consulting</h1>
            <p>Transformando o Impossível Através da Tecnologia</p>
            <a href='https://wa.me/5548991039199?text=Ol%C3%A1%2C%20vim%20pelo%20site%20da%20L%26L%20IT%20Consulting%2C%20quero%20conhecer%20mais%20sobre%20a%20empresa%20e%2Fou%20solicitar%20um%20servi%C3%A7o' className='contact-btn'>Entre Em Contato</a>
            </div>
            <div className='img-container'>
                <img src={globo} alt='Globo'/>
            </div>
            </section>
    );
};

export default Hero;
