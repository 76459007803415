import React from 'react';
import '../styles/Contact.css'; // Import the CSS file
import phoneIcon from '../imgs/whatsapp.png'; // Replace with actual icon paths
import mailIcon from '../imgs/mail.png';

const ContactPage = () => {
  return (
    <div id='contato' className="contact-container">
      <div className="contact-content">
        <h1>Entre em Contato e<br />Faça Seu Site<br /><span className="highlight">Agora Mesmo!</span></h1>
        <a href="https://wa.me/5548991039199?text=Ol%C3%A1%2C%20vim%20pelo%20site%20da%20L%26L%20IT%20Consulting%2C%20quero%20conhecer%20mais%20sobre%20a%20empresa%20e%2Fou%20solicitar%20um%20servi%C3%A7o" className="contact-link">Entre Em Contato</a> {/* Transformed button to link */}
      </div>
      <div className="contact-info">
        <div className="info-item">
          <img src={phoneIcon} alt="phone" />
          <div>
            <p>TELEFONE</p>
            <a href='https://wa.me/5548991039199?text=Ol%C3%A1%2C%20vim%20pelo%20site%20da%20L%26L%20IT%20Consulting%2C%20quero%20conhecer%20mais%20sobre%20a%20empresa%20e%2Fou%20solicitar%20um%20servi%C3%A7o'>
              (48) 99103-9199
            </a>
          </div>
        </div>
        <div className="info-item">
          <img src={mailIcon} alt="email" />
          <div>
            <p>EMAIL</p>
            <a href='matildo:itconsultingll@gmail.com'>itconsultingll@gmail.com</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
